import React, { useCallback, useEffect, useRef, useState } from "react";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import { Label } from "../../ui/label";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "../../ui/select";
import { BiltyItemDataType } from "../../../lib/DataType/BiltyDataType";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (data: BiltyItemDataType) => void;
  item?: BiltyItemDataType;
}

const BiltyAddItemModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  item,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [itemName, setItemName] = useState(item?.item || "");
  const [packagingType, setPackagingType] = useState(
    item?.packaging_type || "",
  );
  const [noOfArticle, setNoOfArticle] = useState("");
  const [totalWeight, setTotalWeight] = useState(item?.weight || "");
  const [weightUnit, setWeightUnit] = useState(item?.capacity || "Kg");
  const [frightQtl, setFrightQtl] = useState(item?.qty || "");
  const [amount, setAmount] = useState(item?.amount || "");

  const packagingTypes = ["Packet", "Bag", "carton", "drum", "pallet", "other"];
  const weightQuantities = ["Kg", "litres"];

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.closest('[role="listbox"]')) {
        return;
      }
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose, handleClickOutside]);

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const Itemstate: BiltyItemDataType = {
      amount,
      capacity: weightUnit,
      item: itemName,
      packaging_type: packagingType,
      qty: frightQtl,
      weight: totalWeight,
    };
    if (onSubmit) {
      onSubmit(Itemstate);
    }
    console.log("Itemstate", Itemstate);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 h-[100vh] m-0 bg-black bg-opacity-10 flex items-center justify-center z-50 p-10">
      <div
        className="bg-white rounded-lg shadow-lg w-full max-w-md animate-in fade-in duration-200"
        ref={modalRef}
      >
        <form className="space-y-3 p-5" onSubmit={onSubmitHandler}>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Item Name
            </Label>
            <Input
              required
              name="itemName"
              type="text"
              placeholder="Enter Item Name"
              className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
            />
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Packaging Type
            </Label>
            <Select
              name="packagingType"
              required
              onValueChange={setPackagingType}
              value={packagingType}
            >
              <SelectTrigger className="w-full bg-neutral-50 border-neutral-200">
                <SelectValue
                  placeholder="Select Packaging Type"
                  className="bg-neutral-50 border-neutral-200"
                />
                <SelectContent>
                  {packagingTypes.map((type) => (
                    <SelectItem
                      key={type}
                      value={type}
                      className="bg-neutral-50 border-neutral-200"
                    >
                      {type}
                    </SelectItem>
                  ))}
                </SelectContent>
              </SelectTrigger>
            </Select>
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              No. of Article
            </Label>
            <Input
              required
              name="noOfArticle"
              type="text"
              placeholder="Enter Article Count"
              className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
              value={noOfArticle}
              onChange={(e) => setNoOfArticle(e.target.value)}
            />
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Total Weight
            </Label>
            <div className="flex w-full">
              <Input
                required
                name="totalWeight"
                type="text"
                placeholder="Enter Total Weight"
                className="w-full bg-neutral-50 border-r-0 rounded-r-none border-neutral-200 text-sm text-neutral-800"
                value={totalWeight}
                onChange={(e) => setTotalWeight(e.target.value)}
              />
              <Select
                name="weightUnit"
                required
                onValueChange={setWeightUnit}
                value={weightUnit}
              >
                <SelectTrigger className="rounded-l-none bg-neutral-50 border-neutral-200 max-w-24">
                  <SelectValue
                    placeholder={weightUnit}
                    className="rounded-l-none bg-neutral-50 border-neutral-200 max-w-24"
                  />
                </SelectTrigger>
                <SelectContent className="rounded-l-none bg-neutral-50 border-neutral-200 max-w-24">
                  {weightQuantities.map((quantity) => (
                    <SelectItem
                      key={quantity}
                      value={quantity}
                      className="bg-neutral-50"
                    >
                      {quantity}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Fright/QTL
            </Label>
            <Input
              required
              name="frightQtl"
              type="text"
              placeholder="Enter Fright/QTL"
              className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
              value={frightQtl}
              onChange={(e) => setFrightQtl(e.target.value)}
            />
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Amount
            </Label>
            <Input
              required
              name="amount"
              type="text"
              placeholder="Enter Amount"
              className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
          <div className="flex justify-between gap-5 items-center pt-4">
            <Button
              className="bg-cardinal-600 w-24 sm:w-full sm:max-w-[15rem] hover:bg-cardinal-600"
              onClick={() => {
                /* Handle add item logic here */
              }}
            >
              Add Item
            </Button>
            <Button
              className="bg-blue-600 w-24 sm:w-full sm:max-w-[15rem] hover:bg-blue-600"
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BiltyAddItemModal;
