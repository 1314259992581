import { useEffect, useState } from "react";
import { PiBell } from "react-icons/pi";
import { Link, useLocation } from "react-router-dom";
import {
  CircleUserRound,
  ChevronRight,
  Share2,
  ArrowDownToLine,
} from "lucide-react";
import Sidebar from "./sidebar";
import { Button } from "../ui/button";
import clsx from "clsx";
import {
  DropdownMenuTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "../ui/dropdown-menu";
import { IoLogOutOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { logout } from "../../store/userSlice";
import { confirmAlert } from "react-confirm-alert";

interface NavbarProps {
  isLargeScreen: boolean;
  setIsLargeScreen: (arg: boolean) => void;
}

function Navbar({ isLargeScreen, setIsLargeScreen }: NavbarProps) {
  const location = useLocation();
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false);
  const locationPath = location.pathname;

  useEffect(() => {
    function handleResize() {
      const isLarge = window.innerWidth >= 1024;
      setIsLargeScreen(isLarge);
      setIsOpen(isLarge);
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setIsLargeScreen]);

  const logoutConfirm = () => {
    confirmAlert({
      title: 'Confirm Logout',
      message: 'Are you sure you want to log out?',
      buttons: [
        {
          label: 'Yes, Logout',
          onClick: () => dispatch(logout()) // Triggers the logout action
        },
        {
          label: 'Cancel',
          onClick: () => {} // Optional cancel action
        }
      ]
    });
  };

  return (
    <nav
      className={clsx(
        `h-12 fixed z-50 bg-white flex items-center w-full pb-2 border-b ${isLargeScreen ? "pl-[199px]" : ""}`,
      )}
    >
      <Sidebar
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isLargeScreen={isLargeScreen}
      />
      <div className="ml-8 flex items-center gap-2">
        <Link to="/">
          <p className="text-sm sm:text-base text-cardinal-600">Home</p>
        </Link>
        {locationPath === "/bilty" && (
          <div className="">
            <p className="flex text-sm sm:text-base items-center text-cardinal-600">
              <ChevronRight size={16} className="mr-1 text-cardinal-600" />
              Bilty
            </p>
          </div>
        )}
        {locationPath === "/khata" && (
          <div className="">
            <p className="flex text-sm sm:text-base items-center text-cardinal-600">
              <ChevronRight size={16} className="mr-1 text-cardinal-600" />
              Khata
            </p>
          </div>
        )}
        {(locationPath === "/bilty/create" ||
          locationPath === "/bilty/create/consigment") && (
            <div className="flex items-center gap-2">
              <Link to="/bilty" className="">
                <p className="flex text-sm sm:text-base items-center text-cardinal-600">
                  <ChevronRight size={16} className="mr-1 text-cardinal-600" />
                  Bilty
                </p>
              </Link>
              <div className="">
                <p className="flex text-sm sm:text-base items-center text-cardinal-600">
                  <ChevronRight size={16} className="mr-1 text-cardinal-600" />
                  Create
                </p>
              </div>
            </div>
          )}
        {locationPath === "/bilty/details" && (
          <div className="flex items-center gap-2">
            <Link to="/bilty" className="">
              <p className="flex text-sm sm:text-base items-center text-cardinal-600">
                <ChevronRight size={16} className="mr-1 text-cardinal-600" />
                Bilty
              </p>
            </Link>
            <div className="flex-1">
              <p className="flex text-sm sm:text-base items-center text-cardinal-600 flex-1 w-32">
                <ChevronRight size={16} className="mr-1 text-cardinal-600" />
                Bilty Details
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="w-full flex justify-end items-center mr-3 pr-[1.5rem]">
        {locationPath === "/bilty/details" ? (
          <div className="flex items-center gap-3">
            <Button className="rounded-full p-2.5 flex items-center justify-center text-white bg-blue-600 hover:bg-blue-600 shadow-none">
              <Share2 size={16} />
            </Button>
            <Button className="rounded-full p-2.5 flex items-center justify-center text-white bg-green-600 hover:bg-green-600 shadow-none">
              <ArrowDownToLine size={16} />
            </Button>
          </div>
        ) : (
          <div className="flex gap-3 items-center">
            <Button className="rounded-full px-2.5 flex items-center justify-center text-cardinal-500 bg-neutral-200 hover:bg-neutral-200 shadow-none">
              <PiBell size={16} />
            </Button>
            <DropdownMenu>
              {/* drop down logic goes here */}
              <DropdownMenuTrigger asChild>
                <div className="rounded-full cursor-pointer p-2.5 flex items-center justify-center text-cardinal-500 bg-neutral-200 hover:bg-neutral-200 shadow-none">
                  <CircleUserRound size={16} />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-16 mr-3">
                <DropdownMenuLabel>My Account</DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuGroup>
                  {/* add log-out logic here  */}
                  <DropdownMenuItem
                    className="cursor-pointer w-full flex justify-between gap-2"
                    onClick={logoutConfirm}
                  >
                    <p>Logout</p>
                    <IoLogOutOutline className="h-4 w-4" />
                  </DropdownMenuItem>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
            {locationPath !== "/bilty/create" &&
              locationPath !== "/khata" &&
              locationPath !== "/bilty/create/consigment" && (
                <div className="flex items-center gap-2">
                  <p className="hidden sm:flex">Inventory log</p>
                  <img src="/depth.png" alt="profile" />
                </div>
              )}
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
