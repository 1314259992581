import { ResDataType } from "../DataType/ApiCommon";
import {
  LoginRequestDataType,
  ProfileDataType,
} from "../DataType/AuthDataType";
import { TRANSPORTER_URL } from "../Utils/EnvVariables";
import HttpClient from "../Utils/HttpClient";

export const SendOtpServive = (contact_no: string): Promise<ResDataType> => {
  return HttpClient.post(`${TRANSPORTER_URL}/auth/login`, { contact_no });
};

export const LoginService = (
  request: LoginRequestDataType,
): Promise<ResDataType<ProfileDataType>> => {
  return HttpClient.post(`${TRANSPORTER_URL}/auth/login-otp`, request);
};

export const UpdateProfileService = (
  request: Record<string, any>,
): Promise<ResDataType<ProfileDataType>> => {
  return HttpClient.post(`${TRANSPORTER_URL}/profile/profile-update`, request);
};
