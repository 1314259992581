import { Pencil, Trash2 } from "lucide-react";
import { Button } from "../../ui/button";
import { useLocation } from "react-router-dom";
import { BiltyItemDataType } from "../../../lib/DataType/BiltyDataType";

export interface ItemDetailsCardProps extends BiltyItemDataType {
  onEdit?: () => void;
  onDelete?: () => void;
}

const BiltyItemDetailsCard: React.FC<ItemDetailsCardProps> = ({
  amount,
  capacity,
  item,
  packaging_type,
  qty,
  weight,
  onDelete,
  onEdit,
}) => {
  const location = useLocation();
  return (
    <div className="rounded-md p-3 h-full min-h-40 border shadow-sm flex flex-col justify-between text-xs sm:text-sm w-full">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-neutral-400 text-xs">Item Name</p>
          <p className="font-semibold">{item}</p>
        </div>
        <div>
          <p className="text-neutral-400 text-xs">Packaging Type</p>
          <p className="font-semibold text-right  ">{packaging_type}</p>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <p className="text-neutral-400 text-xs">Quantity</p>
          <p className="font-semibold">{qty}</p>
        </div>
        <div>
          <p className="text-neutral-400 text-xs text-right">Weight</p>
          <p className="font-semibold">
            {weight} {capacity}
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <p className="text-neutral-400 text-xs">Amount</p>
          <p className="font-semibold">₹ {amount}</p>
        </div>
        {location.pathname === "/bilty/create/consigment" && (
          <div className="flex items-center gap-2">
            {onEdit && (
              <Button
                onClick={onEdit}
                type="button"
                className="rounded-full p-2.5 flex items-center justify-center text-white bg-blue-500 hover:bg-blue-500 shadow-none"
              >
                <Pencil size={12} />
              </Button>
            )}

            {onDelete && (
              <Button
                type="button"
                onClick={onDelete}
                className="rounded-full p-2.5 flex items-center justify-center text-white bg-cardinal-600 hover:bg-cardinal-500 shadow-none"
              >
                <Trash2 size={12} />
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BiltyItemDetailsCard;
