import LogoImage from "../logo-image";
import { CiLock } from "react-icons/ci";
import { Input } from "../../ui/input";
import { Pencil } from "lucide-react";
import { REGEXP_ONLY_DIGITS } from "input-otp";
import { InputOTP, InputOTPSlot, InputOTPGroup } from "../../ui/input-otp";
import { Label } from "../../ui/label";
import { Button } from "../../ui/button";
import { useState, useEffect, useCallback } from "react";
import SuccessModal from "../modals/success-modal";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LoginService, SendOtpServive } from "../../../lib/Service/AuthService";
import { useDispatch } from "react-redux";
import { setLogin } from "../../../store/userSlice";
import { toast } from "react-toastify";

const LoginOtpVerificationForm: React.FC = () => {
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState<number>(30);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const mobile = searchParams.get("mobile") || null;
  const [otp, setOtp] = useState<string>("");
  const [isLogin, setIsLogin] = useState<boolean>(false);

  if (mobile == null || mobile === "") {
    navigate("/login");
  }

  const sendOtp = useCallback(() => {
    if (mobile !== null) {
      SendOtpServive(mobile.slice(3))
        .then((data) => {
          console.log("data", data);
          if (data.status) {
            setIsActive(true);
          }
        })
        .catch(() => {});
    }
  }, [mobile]);

  useEffect(() => {
    sendOtp();
  }, [sendOtp]);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (isActive && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsActive(false);
      clearInterval(interval as unknown as NodeJS.Timeout);
    }

    return () => clearInterval(interval as NodeJS.Timeout);
  }, [isActive, timeLeft]);

  const formattedTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60)
      .toString()
      .padStart(2, "0");
    const secs = (seconds % 60).toString().padStart(2, "0");
    return `${mins}:${secs}`;
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  //  todo add modal logic here
  const toggleShowModal = (isContinue?: boolean) => {
    setShowModal((prev) => !prev);
    if (isContinue && isLogin) {
      window.location.href = "/";
    }
  };

  const handleVerifyOTP = () => {
    if (mobile) {
      LoginService({
        contact_no: mobile.slice(3),
        device_token: "",
        device_type: "web",
        otp: otp,
      })
        .then((data) => {
          if (data.status && data.token) {
            setIsLogin(true);
            dispatch(
              setLogin({
                name: data.data.contact_person,
                business_name: data.data.business_name,
                token: data.token,
                isLogin: true,
                useData: data.data,
              }),
            );
            if (data.data.is_profile_complate) {
              toast.success(data.message, {
                position: "top-right",
                theme: "colored",
              });
              window.location.href = "/";
            } else {
              window.location.href = "/signup/verification/profile1";
              // setShowModal((prev) => !prev);
              // setIsActive(false);
            }
          }
        })
        .catch((error) => {
          if (typeof error.message === "string") {
            toast.error(error.message, {
              position: "top-right",
              theme: "colored",
            });
          }
        });
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="w-full max-w-md p-5">
      <LogoImage height={100} />
      <div className="flex items-center justify-center gap-2 pt-3">
        <CiLock className="w-6 h-6 text-cardinal-700" />
        <p className="font-semibold">OTP Verification</p>
      </div>
      <form className="flex flex-col space-y-5 pt-3" onSubmit={handleSubmit}>
        <p className="text-neutral-500 text-center text-sm  ">
          Enter a 6 digit verification code we just send you on your mobile
        </p>
        <div className="flex flex-col space-y-3">
          <div className="relative w-full max-w-md">
            <Input
              type="text"
              defaultValue={`+91 - ${(mobile as string).slice(3)}`} // logic of adding country code might change accordingly according to roadlyne needs or api needs
              disabled
              className="pl-3 pr-10 py-5 text-base text-neutral-700 bg-neutral-50 rounded-md"
            />
            <button
              className="absolute right-2 top-1/2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 transition-colors"
              aria-label="Edit phone number"
            >
              <Pencil className="h-4 w-4" />
            </button>
          </div>
          <div className="flex flex-col space-y-1 mt-2">
            <Label className="text-sm">Enter OTP</Label>
            <InputOTP
              maxLength={6}
              pattern={REGEXP_ONLY_DIGITS}
              onChange={setOtp}
            >
              <InputOTPGroup className="w-full justify-between">
                <InputOTPSlot
                  index={0}
                  className=" h-12 w-12 border border-neutral-200"
                />
                <InputOTPSlot
                  index={1}
                  className=" h-12 w-12 border border-neutral-200"
                />
                <InputOTPSlot
                  index={2}
                  className=" h-12 w-12 border border-neutral-200"
                />
                <InputOTPSlot
                  index={3}
                  className=" h-12 w-12 border border-neutral-200"
                />
                <InputOTPSlot
                  index={4}
                  className=" h-12 w-12 border border-neutral-200"
                />
                <InputOTPSlot
                  index={5}
                  className=" h-12 w-12 border border-neutral-200"
                />
              </InputOTPGroup>
            </InputOTP>
          </div>
          <Button
            className="w-full bg-cardinal-700 hover:bg-cardinal-700"
            onClick={handleVerifyOTP}
          >
            Verify OTP
          </Button>
          {/* <div className="w-full">
            <Dialog>
              <DialogTrigger className="w-full">
                <DialogContent className="sm:max-w-md">
                  <div className="min-h-48">

                  </div>
                  <DialogDescription className="space-y-2 w-full text-black">
                    <p className="w-full font-semibold text-center">
                      Thankyou !
                    </p>
                    <p className="text-center">
                      Thank you for registering with <span className="font-semibold">Roadlyne</span> our team will properly verify your account 
                    </p>
                  </DialogDescription>
                  <DialogFooter className="sm:justify-start">
                    <DialogClose className="w-full">
                      <Button type="button" className="w-full bg-black hover:bg-black">
                        Continue
                      </Button>
                    </DialogClose>
                  </DialogFooter>
                </DialogContent>
              </DialogTrigger>
            </Dialog>
          </div> */}
          <div className="w-full flex flex-col justify-center items-center">
            <p className="text-neutral-500 text-xs sm:text-xs">
              Code Expires in
            </p>
            <p className="text-green-500 text-xl font-medium">
              {formattedTime(timeLeft)}
            </p>
          </div>
        </div>
      </form>
      {showModal && <SuccessModal closeModal={toggleShowModal} />}
    </div>
  );
};

export default LoginOtpVerificationForm;
