import { Link } from "react-router-dom";
import { IoCloseOutline, IoChevronBack } from "react-icons/io5";
import { Separator } from "../../components/ui/separator";
import { useNavigate } from "react-router-dom";
import SignupOtpVerificationForm from "../../components/app/forms/SignupOTPVerificationForm";

const SignupVerificationPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <main
      className="min-h-screen relative bg-cover bg-center"
      style={{
        backgroundImage: "url(/login/image-10.png)",
      }}
    >
      <div className="absolute top-4 right-4">
        <Link to="/">
          <IoCloseOutline className="w-6 h-6 text-neutral-600 cursor-pointer" />
        </Link>
      </div>
      <div className="absolute top-4 left-4">
        <div onClick={handleGoBack}>
          <IoChevronBack className="w-6 h-6 text-neutral-600 cursor-pointer" />
        </div>
      </div>
      <div className="min-h-screen flex flex-col items-center justify-center space-y-5">
        <SignupOtpVerificationForm />
        <Separator className="w-full max-w-lg text-neutral-600" />
        <div className="flex text-xs gap-2">
          <p>Have an account?</p>
          <Link to="/login" className="text-cardinal-700">
            Login
          </Link>
        </div>
      </div>
    </main>
  );
};

export default SignupVerificationPage;
