import { ModalProps } from "../../../lib/definitions";
import React, { useEffect, useRef, useState } from "react";
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import InputDatePicker from "../date-picker";
import { CircleX } from "lucide-react";
import {
  KhataAmountRequestDataType,
  KhataTransactionDataType,
} from "../../../lib/DataType/KhataDataType";
import {
  AddKataAmountService,
  UpdateKataAmountService,
} from "../../../lib/Service/KhataService";
import moment from "moment";
import { toast } from "react-toastify";
import { ErrorHandler } from "../../../lib/Utils/Common";

export interface KhataEditHistoryModalProps
  extends ModalProps<KhataTransactionDataType> {
  item: KhataTransactionDataType | null;
  isEdit?: boolean;
  type: number;
  id: string | number;
}

const KhataAmountHistoryModal: React.FC<KhataEditHistoryModalProps> = ({
  isOpen,
  onClose,
  item,
  isEdit = false,
  type,
  onSubmit,
  id,
}) => {
  const [amount, setAmount] = useState<string>(item?.amount.toString() ?? "");
  const [date, setDate] = useState<Date>(
    item?.date ? new Date(item?.date) : new Date(),
  );
  const [reason, setReason] = useState<string>(item?.reason || "");

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = { amount, date, reason };
    console.log(data); // Process form data here

    const req: KhataAmountRequestDataType = {
      amount_type: type,
      amount: Number(amount),
      date: moment(date).format("YYYY/MM/DD"),
      reason,
      order_id: null,
    };
    console.log("req", req);

    if (isEdit && item !== null) {
      UpdateKataAmountService(item.id, req)
        .then((data) => {
          if (data.status) {
            toast.success(data.message, {
              position: "top-right",
              theme: "colored",
            });
            console.log("data", data);
            if (onSubmit) {
              onSubmit(data.data);
            }
          }
        })
        .catch(ErrorHandler);
    } else {
      AddKataAmountService(id, req)
        .then((data) => {
          if (data.status) {
            toast.success(data.message, {
              position: "top-right",
              theme: "colored",
            });
            if (onSubmit) {
              onSubmit(data.data);
            }
          }
        })
        .catch(ErrorHandler);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 h-[100vh] m-0 bg-black bg-opacity-10 flex items-center justify-center z-50 p-10">
      <div
        className="bg-white rounded-lg shadow-lg w-full max-w-md animate-in fade-in duration-200 p-5"
        ref={modalRef}
      >
        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          {!isEdit && (
            <h3 className="text-center font-bold">
              {type === 1 ? "To Pay" : "To Recieve"}
            </h3>
          )}
          <div>
            <Label htmlFor="amount" className="text-xs">
              Enter Amount
            </Label>
            <div className="relative w-full">
              <Input
                required
                name="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                type="text"
                placeholder="Amount"
                className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800 pr-6"
              />
              {amount !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => setAmount("")}
                />
              )}
            </div>
          </div>

          <div>
            <Label htmlFor="reason" className="text-xs">
              Reason
            </Label>
            <div className="relative w-full">
              <Input
                required
                name="reason"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                type="text"
                placeholder="Reason"
                className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800 pr-6"
              />
              {reason !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => setReason("")}
                />
              )}
            </div>
          </div>

          <div>
            <Label htmlFor="date" className="text-xs">
              Date
            </Label>
            <InputDatePicker
              date={date}
              onChange={(newDate: Date) => setDate(newDate)}
            />
          </div>

          <Button
            type="submit"
            className="w-full bg-cardinal-500 hover:bg-cardinal-600 text-white py-3 rounded-lg"
          >
            Update
          </Button>
        </form>
      </div>
    </div>
  );
};

export default KhataAmountHistoryModal;
