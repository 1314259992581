import { toast } from "react-toastify";

type ValidationErrorResponse = {
  message: string;
  type: "validation";
  errors: Record<string, string[]>;
};

export const ErrorHandler = (error: ValidationErrorResponse) => {
  if (typeof error.message === "string") {
    toast.error(error.message, {
      position: "top-right",
      theme: "colored",
    });
  } else if (error.type === "validation") {
    const messages = Object.values(error.errors).flat();
    messages?.forEach((message) => {
      toast.error(message, {
        position: "top-right",
        theme: "colored",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    });
  } else {
    toast.error("something went wrong please try again", {
      position: "top-right",
      theme: "colored",
    });
  }
};

/**
 * Validates a license number based on the specified regex.
 * @param licenseNumber - The license number to validate.
 * @returns true if valid, otherwise false.
 */
export function ValidateLicenseNumber(licenseNumber: string): boolean {
  const licenseRegex = /^[A-Za-z]{2}[0-9]{2}[0-9A-Za-z]{1,15}$/;
  return licenseRegex.test(licenseNumber) || licenseNumber === "";
}

/**
 * Validates a GST number based on the specified regex.
 * @param gstNumber - The GST number to validate.
 * @returns true if valid, otherwise false.
 */
export function ValidateGSTNumber(gstNumber: string): boolean {
  const gstRegex =
    /^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}$/;
  return gstRegex.test(gstNumber) || gstNumber === "";
}

/**
 * Validates a vehicle number based on the specified regex.
 * @param vehicleNumber - The vehicle number to validate.
 * @returns true if valid, otherwise false.
 */
export function ValidateVehicleNumber(vehicleNumber: string): boolean {
  const vehicleRegex = /^[A-Z]{2}[- ]?[0-9]{2}[- ]?[A-Z]{0,2}[- ]?[0-9]{4}$/;
  return vehicleRegex.test(vehicleNumber) || vehicleNumber === "";
}

/**
 * Checks if the expiry date is after the invoice date.
 * @param invoiceDate - The invoice generation date in YYYY-MM-DD format.
 * @param expiryDate - The expiry date in YYYY-MM-DD format.
 * @returns true if expiryDate is after invoiceDate, otherwise false.
 */
export function IsExpiryDateValid(
  invoiceDate: string,
  expiryDate: string,
): boolean {
  const invoice = new Date(invoiceDate);
  const expiry = new Date(expiryDate);
  return expiry > invoice;
}
