import React, { useState } from "react";
import { Input } from "../../ui/input";
import { ValidateGSTNumber } from "../../../lib/Utils/Common";

export default function BiltyConsignorConsigneeForm() {
  return (
    <div className="my-4 grid sm:grid-cols-1 gap-3">
      <ConsigneeForm />
      <ConsignorForm />
    </div>
  );
}

const ConsigneeForm: React.FC = () => {
  const [gst, setGst] = useState<string>("");
  const inputClasses: string =
    "w-full bg-neutral-50 border border-neutral-200 text-neutral-800";

  return (
    <div className="space-y-2 w-full">
      <h3 className="font-semibold sm:text-lg">Consignee</h3>
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-2">
        <Input
          type="text"
          required
          name="consignee_name"
          placeholder="Consignee"
          className={inputClasses}
        />
        <Input
          type="text"
          required
          name="consignee_mobile_no"
          placeholder="Mobile Number"
          className={inputClasses}
        />
        <Input
          type="text"
          required
          name="consignee_gst_number"
          onChange={(e) => setGst(e.target.value)}
          placeholder="GST Number"
          className={`${inputClasses} ${!ValidateGSTNumber(gst) ? "border-red-500" : "border-neutral-200"}`}
        />
        <Input
          type="text"
          required
          name="consignee_address"
          placeholder="Address"
          className={inputClasses}
        />
      </div>
    </div>
  );
};

const ConsignorForm: React.FC = () => {
  const [gst, setGst] = useState<string>("");
  const inputClasses: string =
    "w-full bg-neutral-50 border border-neutral-200 text-neutral-800";

  return (
    <div className="space-y-2 w-full">
      <h3 className="font-semibold sm:text-lg">Consignor</h3>
      <div className="grid grid-cols-1 sm:grid-cols-4 gap-2">
        <Input
          type="text"
          required
          name="consignor_name"
          placeholder="Consignor"
          className={inputClasses}
        />
        <Input
          type="text"
          required
          name="consignor_mobile_no"
          placeholder="Mobile Number"
          className={inputClasses}
        />
        <Input
          type="text"
          required
          name="consignor_gst_number"
          placeholder="GST Number"
          onChange={(e) => setGst(e.target.value)}
          className={`${inputClasses} ${!ValidateGSTNumber(gst) ? "border-red-500" : "border-neutral-200"}`}
        />
        <Input
          type="text"
          required
          name="consignor_address"
          placeholder="Address"
          className={inputClasses}
        />
      </div>
    </div>
  );
};
