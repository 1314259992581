import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProfileDataType } from "../lib/DataType/AuthDataType";

interface UserState {
  name: string | null;
  business_name: string | null;
  useData: ProfileDataType | null;
  token: string | null;
  isLogin: boolean;
}

const initialState: UserState = {
  name: null,
  business_name: null,
  useData: null,
  token: null,
  isLogin: false,
};

const savedUser = localStorage.getItem("user");
const persistedState: UserState = savedUser
  ? JSON.parse(savedUser)
  : initialState;

const userSlice = createSlice({
  name: "user",
  initialState: persistedState,
  reducers: {
    setLogin(state, action: PayloadAction<UserState>) {
      state.name = action.payload.name;
      state.business_name = action.payload.business_name;
      state.token = action.payload.token;

      localStorage.setItem("token", action.payload.token || "");
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    setUser(state, action: PayloadAction<ProfileDataType>) {
      state.name = action.payload.contact_person;
      state.useData = action.payload;
      localStorage.setItem("user", JSON.stringify(state));
    },
    logout(state) {
      state.name = "";
      state.token = "";
      localStorage.clear();
      window.location.href = "/"
    },
  },
});

export const { setUser, setLogin, logout } = userSlice.actions;
export default userSlice.reducer;
