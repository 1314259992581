import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BiltyDataType,
  BiltyFieldDataType,
  BiltyTruckDetailsDataType,
  ConsignorConsigneeInfoDataType,
} from "../lib/DataType/BiltyDataType";

const initialState: BiltyDataType = {
  bilty_commission: "",
  consignor_consignee_info: [],
  date: "",
  driver_name: "",
  driver_number: "",
  e_way_bill_number: "",
  expiry_date: "",
  fleet_owner: "",
  fleet_owner_number: "",
  from_city_id: "",
  gst_number: "",
  invoice_date: "",
  invoice_generation_date: "",
  invoice_number: "",
  license_number: "",
  lr_bilty_number: "",
  order_id: "",
  remark: "",
  to_city_id: "",
  vehicle_number: "",
  fields: [],
};

const CreateBiltySlice = createSlice({
  name: "createBilty",
  initialState,
  reducers: {
    // Setters for each field
    setBiltyCommission: (state, action: PayloadAction<string>) => {
      state.bilty_commission = action.payload;
    },
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    setDriverName: (state, action: PayloadAction<string>) => {
      state.driver_name = action.payload;
    },
    setDriverNumber: (state, action: PayloadAction<string>) => {
      state.driver_number = action.payload;
    },
    setEWayBillNumber: (state, action: PayloadAction<string>) => {
      state.e_way_bill_number = action.payload;
    },
    setExpiryDate: (state, action: PayloadAction<string>) => {
      state.expiry_date = action.payload;
    },
    setFleetOwner: (state, action: PayloadAction<string>) => {
      state.fleet_owner = action.payload;
    },
    setFleetOwnerNumber: (state, action: PayloadAction<string>) => {
      state.fleet_owner_number = action.payload;
    },
    setFromCityId: (state, action: PayloadAction<string>) => {
      state.from_city_id = action.payload;
    },
    setGstNumber: (state, action: PayloadAction<string>) => {
      state.gst_number = action.payload;
    },
    setInvoiceDate: (state, action: PayloadAction<string>) => {
      state.invoice_date = action.payload;
    },
    setInvoiceGenerationDate: (state, action: PayloadAction<string>) => {
      state.invoice_generation_date = action.payload;
    },
    setInvoiceNumber: (state, action: PayloadAction<string>) => {
      state.invoice_number = action.payload;
    },
    setLicenseNumber: (state, action: PayloadAction<string>) => {
      state.license_number = action.payload;
    },
    setLrBiltyNumber: (state, action: PayloadAction<string>) => {
      state.lr_bilty_number = action.payload;
    },
    setOrderId: (state, action: PayloadAction<string>) => {
      state.order_id = action.payload;
    },
    setRemark: (state, action: PayloadAction<string>) => {
      state.remark = action.payload;
    },
    setToCityId: (state, action: PayloadAction<string>) => {
      state.to_city_id = action.payload;
    },
    setVehicleNumber: (state, action: PayloadAction<string>) => {
      state.vehicle_number = action.payload;
    },

    // Add and remove functions for consignor_consignee_info
    addConsignorConsigneeInfo: (
      state,
      action: PayloadAction<ConsignorConsigneeInfoDataType>,
    ) => {
      state.consignor_consignee_info.push(action.payload);
    },
    removeConsignorConsigneeInfo: (state, action: PayloadAction<number>) => {
      state.consignor_consignee_info.splice(action.payload, 1);
    },

    // Add and remove functions for fields
    addField: (state, action: PayloadAction<BiltyFieldDataType>) => {
      state.fields.push(action.payload);
    },
    removeField: (state, action: PayloadAction<number>) => {
      state.fields.splice(action.payload, 1);
    },
    setTruckDetails: (
      state,
      action: PayloadAction<BiltyTruckDetailsDataType>,
    ) => {
      state.vehicle_number = action.payload.vehicle_number;
      state.driver_number = action.payload.driver_number;
      state.fleet_owner = action.payload.fleet_owner;
      state.gst_number = action.payload.gst_number;
      state.license_number = action.payload.license_number;
      state.driver_name = action.payload.driver_name;
    },
  },
});

export const {
  setBiltyCommission,
  setDate,
  setDriverName,
  setDriverNumber,
  setEWayBillNumber,
  setExpiryDate,
  setFleetOwner,
  setFleetOwnerNumber,
  setFromCityId,
  setGstNumber,
  setInvoiceDate,
  setInvoiceGenerationDate,
  setInvoiceNumber,
  setLicenseNumber,
  setLrBiltyNumber,
  setOrderId,
  setRemark,
  setToCityId,
  setVehicleNumber,
  addConsignorConsigneeInfo,
  removeConsignorConsigneeInfo,
  addField,
  removeField,
  setTruckDetails,
} = CreateBiltySlice.actions;

export default CreateBiltySlice.reducer;
