import { configureStore } from "@reduxjs/toolkit";

import userSlice from "./userSlice";
import CreateBiltySlice from "./CreateBiltySlice";

const store = configureStore({
  reducer: {
    user: userSlice,
    createBilty: CreateBiltySlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
