import React from "react";

export interface ConsignorDetails {
  consignor: string;
  gstNumber: string;
  mobile: string;
  address: string; // this depened how roadlyne is storing address in the backend / db
}

export interface ConsigneeDetails {
  consignee: string;
  gstNumber: string;
  mobile: string;
  address: string;
}

const BiltyConsignorConsigneeCard: React.FC<{
  consignorData: ConsignorDetails;
  consigneeData: ConsigneeDetails;
}> = ({ consigneeData, consignorData }) => {

  return (
    <div className="p-3 rounded-md  grid xl:grid-cols-2 justify-center gap-2 text-xs sm:text-sm">
      <ConsignorDetailsCard {...consignorData} />
      {/* consignee thing goes here */}
      <ConsigneeDetailsCard {...consigneeData} />
    </div>
  );
};

export default BiltyConsignorConsigneeCard;

function ConsignorDetailsCard({ ...props }: ConsignorDetails) {
  return (
    <div className="grid sm:grid-cols-2 gap-2 justify-between text-xs p-2 border-b xl:border-r xl:border-b-0">
      <div>
        <p className="text-neutral-400">Consignor</p>
        <p className="font-semibold ">{props.consignor}</p>
      </div>
      <div>
        <p className="text-neutral-400 sm:text-right">GST Number</p>
        <p className="font-semibold sm:text-right ">{props.gstNumber}</p>
      </div>
      <div>
        <p className="text-neutral-400">Mobile Number</p>
        <p className="font-semibold">{props.mobile}</p>
      </div>
      <div>
        <p className="text-neutral-400 sm:text-right">Address</p>
        <p className="font-semibold text-wrap sm:text-right">{props.address}</p>
      </div>
    </div>
  );
}

function ConsigneeDetailsCard({ ...props }: ConsigneeDetails) {
  return (
    <div className="grid sm:grid-cols-2 gap-2 justify-between text-xs p-2 ">
      <div>
        <p className="text-neutral-400">Consignee</p>
        <p className="font-semibold ">{props.consignee}</p>
      </div>
      <div>
        <p className="text-neutral-400 sm:text-right">GST Number</p>
        <p className="font-semibold sm:text-right ">{props.gstNumber}</p>
      </div>
      <div>
        <p className="text-neutral-400">Mobile Number</p>
        <p className="font-semibold">{props.mobile}</p>
      </div>
      <div>
        <p className="text-neutral-400 sm:text-right">Address</p>
        <p className="font-semibold text-wrap sm:text-right">{props.address}</p>
      </div>
    </div>
  );
}
