import React, { useEffect, useState } from "react";
import BiltyTruckDetailsCard from "../../components/app/cards/bilty-truck-details-card";
import BiltyProgressBarCard from "../../components/app/cards/bilty-progress-bar-card";
import BiltyInvoiceDetailsCard from "../../components/app/cards/bilty-invoice-details-card";
import BiltyConsignorConsigneeCard from "../../components/app/cards/bilty-consignor-consignee-card";
import BiltyItemDetailsCard from "../../components/app/cards/BiltyItemDetailsCard";
import BiltyFrightDetailsCard from "../../components/app/cards/bilty-fright-details-card";
import { ChevronDown, ChevronRight } from "lucide-react";
import { BiltyDetailsDataType } from "../../lib/DataType/BiltyDataType";
import { useParams } from "react-router-dom";
import { GetBiltyDetailsService } from "../../lib/Service/BiltyService";
import { ErrorHandler } from "../../lib/Utils/Common";

const BiltyDetailsPage: React.FC = () => {
  const { id } = useParams();
  const [isConsignmentDetailsExpanded, setIsConsignmentDetailsExpanded] =
    useState<boolean>(false);

  const [biltyDetails, setBiltyDetails] = useState<BiltyDetailsDataType | null>(
    null,
  );

  const toggleConsignmentDetailsExpanded = () => {
    setIsConsignmentDetailsExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (id) {
      GetBiltyDetailsService(id)
        .then((data) => {
          console.log("data", data);
          if (data.status) {
            setBiltyDetails(data.data.data);
          }
        })
        .catch(ErrorHandler);
    }
  }, [id]);

  if (biltyDetails === null) {
    return null;
  }

  return (
    <div className="w-full px-4 mb-10">
      <div>
        <h3 className="sm:text-lg font-bold">
          {/* add that fetched bilty lr number here then */}
          LR Bilty No: {biltyDetails.lr_bilty_number}
        </h3>
        <div className="w-full flex justify-center items-center my-4">
          <BiltyProgressBarCard
            biltyDate={biltyDetails.date}
            biltyStatus={biltyDetails.status}
            from={biltyDetails.from_city}
            orderId={biltyDetails.order_id}
            to={biltyDetails.to_city}
          />
        </div>
      </div>
      <div className="grid md:grid-cols-2 gap-5">
        <div>
          <h3 className="sm:text-lg font-bold">Truck Details</h3>
          <div className="w-full my-4">
            <BiltyTruckDetailsCard
              driverName={biltyDetails.truck_details.driver_name}
              driverNumber={biltyDetails.truck_details.driver_number}
              fleetMobile={""}
              fleetOwner={biltyDetails.truck_details.fleet_owner}
              gstNumber={biltyDetails.truck_details.gst_number}
              licenseNumber={biltyDetails.truck_details.license_number}
              vehicleNumber={biltyDetails.truck_details.vehicle_number}
            />
          </div>
        </div>
        <div>
          <h3 className="sm:text-lg font-bold">Invoice & E-Way Bill Details</h3>
          <div className="w-full my-4">
            <BiltyInvoiceDetailsCard
              billNumber={biltyDetails.e_way_bill_number}
              expiryDate={biltyDetails.expiry_date}
              generationDate={biltyDetails.invoice_generation_date}
              invoiceDate={biltyDetails.invoice_date}
              invoiceNumber={biltyDetails.invoice_number}
            />
          </div>
        </div>
        <div>
          <h3 className="sm:text-lg font-bold">Consignment Details</h3>
          <div className="my-4 p-3  border rounded-md">
            <div
              className="flex items-center justify-between"
              onClick={toggleConsignmentDetailsExpanded}
            >
              <h3 className="font-semibold">Consignor and Consignee Details</h3>
              {isConsignmentDetailsExpanded ? (
                <ChevronDown
                  size={16}
                  className="text-neutral-600 transform transition-all duration-200 ease-in-out rotate-0"
                />
              ) : (
                <ChevronRight
                  size={16}
                  className="text-neutral-600 transform transition-all duration-200 ease-in-out -rotate-90"
                />
              )}
            </div>
            {isConsignmentDetailsExpanded && (
              <div>
                {biltyDetails.consignment_details.map(
                  (consigneeConsignors, i) => {
                    return (
                      <div className="w-full p-3 space-y-3" key={i}>
                        {/* the props are not passed on here fetch the data inside the card component and then pass it into it's two sub cards component */}
                        <BiltyConsignorConsigneeCard
                          consigneeData={{
                            address: consigneeConsignors.consignee_address,
                            consignee: consigneeConsignors.consignee_name,
                            gstNumber: consigneeConsignors.consignee_gst_number,
                            mobile: consigneeConsignors.consignee_name,
                          }}
                          consignorData={{
                            address: consigneeConsignors.consignor_address,
                            consignor: consigneeConsignors.consignor_name,
                            gstNumber: consigneeConsignors.consignor_gst_number,
                            mobile: consigneeConsignors.consignor_name,
                          }}
                        />

                        <div className="space-y-3">
                          <h3 className="font-semibold">Items Details</h3>
                          <div className="grid lg:grid-cols-2 gap-3">
                            {consigneeConsignors.items.map((item, index) => (
                              <BiltyItemDetailsCard
                                key={index}
                                amount={item.amount}
                                capacity={item.capacity}
                                item={item.item}
                                packaging_type={item.packaging_type}
                                qty={item.qty}
                                weight={item.weight}
                              />
                            ))}
                          </div>
                        </div>
                        <h3 className="font-semibold">Fright Details</h3>
                        <BiltyFrightDetailsCard
                          advance={Number(consigneeConsignors.advance_amount)}
                          amount={Number(consigneeConsignors.total_amount)}
                          frightType={consigneeConsignors.freight}
                          pricePerUnit={0}
                          remaining={Number(
                            consigneeConsignors.remaining_amount,
                          )}
                        />
                      </div>
                    );
                  },
                )}
              </div>
            )}
          </div>
        </div>
        <div className="space-y-4">
          <div className="space-y-4">
            <h3 className="sm:text-lg font-bold">Bilty Commission</h3>
            <div className="border p-2 font-semibold bg-neutral-50 rounded-md">
              ₹{biltyDetails.bilty_commission}
            </div>
          </div>
          <div className="space-y-4">
            <h3 className="sm:text-lg font-bold">Bilty Commission</h3>
            <div className="border space-y-3 bg-neutral-50 rounded-md p-2 text-xs sm:text-sm">
              <div className="flex gap-2 justify-between items-center">
                <p className="text-neutral-400">Total (All Consignment)</p>
                <p className="font-semibold">₹{biltyDetails.total_amount}</p>
              </div>
              <div className="flex gap-2 justify-between items-center">
                <p className="text-neutral-400">Advance (All Consignment)</p>
                <p className="font-semibold">₹ {biltyDetails.total_advance}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BiltyDetailsPage;
