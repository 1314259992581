import { ModalProps } from "../../../lib/definitions";
import React, { useEffect, useRef, useState } from "react";
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import { CircleX } from "lucide-react";
import { UpdateKataService } from "../../../lib/Service/KhataService";
import { ErrorHandler } from "../../../lib/Utils/Common";
import { toast } from "react-toastify";

export interface KhataEditAccountDetailsModalProps extends ModalProps {
  bussinessName: string
  name: string
  mobileNumber: string
  khata_id: number
}

const KhataEditAccountDetailsModal: React.FC<KhataEditAccountDetailsModalProps> = ({
  isOpen,
  onClose,
  onSubmit = () => { },
  ...rest
}) => {
  const [formData, setFromData] = useState<any>({
    name: rest.name,
    bussinessName: rest.bussinessName,
    mobileNumber: rest.mobileNumber,
    khata_id: rest.khata_id
  });

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const modalRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('e.target.value',e.target.name, e.target.value)
    setFromData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleInputDelete = (inputName: keyof typeof formData) => {
    setFromData({ ...formData, [inputName]: "" });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    UpdateKataService({
      company_name: formData.bussinessName,
      contact_name: formData.name,
      mobile_no: Number(formData.mobileNumber),
      khata_id: rest.khata_id
    })
      .then((data) => {
        if (data.status) {
          toast.success(data.message, {
            position: "top-right",
            theme: "colored",
          });
          if (onSubmit) {
            onSubmit(data.data);
          }
        } else {
          ErrorHandler(data as any)
        }
      })
      .catch(ErrorHandler);
  };

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 h-[100vh] m-0 bg-black bg-opacity-10 flex items-center justify-center z-50 p-10">
      <div
        className="bg-white rounded-lg shadow-lg w-full max-w-md animate-in fade-in duration-200 p-5"
        ref={modalRef}
      >
        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <div>
            <Label htmlFor="amount" className="text-xs">
              Name
            </Label>
            <div className="relative w-full">
              <Input
                required
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleInputChange}
                type="text"
                className=" w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800 pr-6 "
              />
              {formData.name !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => handleInputDelete("name")}
                />
              )}
            </div>
          </div>
          <div className="">
            <Label htmlFor="reaspn" className="text-xs">
              Business Name
            </Label>
            <div className="relative w-full">
              <Input
                required
                name="bussinessName"
                value={formData.bussinessName}
                placeholder="Business Name"
                onChange={handleInputChange}
                type="text"
                className=" w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800 pr-6 "
              />
              {formData.bussinessName !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => handleInputDelete("bussinessName")}
                />
              )}
            </div>
          </div>
          <div className="">
            <Label htmlFor="reaspn" className="text-xs">
              Phone Number
            </Label>
            <div className="relative w-full">
              <Input
                required
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleInputChange}
                placeholder="Phone Number"
                type="text"
                className=" w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800 pr-6 "
              />
              {formData.mobileNumber !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => handleInputDelete("mobileNumber")}
                />
              )}
            </div>
          </div>
          <Button
            type="submit"
            className="w-full bg-cardinal-500 hover:bg-cardinal-600 text-white py-3 rounded-lg"
          >
            Save
          </Button>
        </form>
      </div>
    </div>
  );
}

export default KhataEditAccountDetailsModal;