import React, { useEffect, useState } from "react";
import { Input } from "../../ui/input";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "../../ui/select";
import InputDatePicker from "../date-picker";
import { useDispatch, useSelector } from "react-redux";
import {
  setDate,
  setFromCityId,
  setToCityId,
  setVehicleNumber,
} from "../../../store/CreateBiltySlice";
import moment from "moment";
import { RootState } from "../../../store";
import { CitieDataType } from "../../../lib/DataType/MasterDataType";
import { GetCities } from "../../../lib/Service/MasterService";
import { ErrorHandler } from "../../../lib/Utils/Common";

const BiltyCreateGeneratingForm: React.FC = () => {
  const { from_city_id, to_city_id, vehicle_number, date } = useSelector(
    (state: RootState) => state.createBilty,
  );
  const dispatch = useDispatch();
  const [citiesList, setCitiesList] = useState<CitieDataType[]>([]);

  const [fromCityFilter, setFromCityFilter] = useState<string>("")
  const [toCityFilter, setToCityFilter] = useState<string>("")

  useEffect(() => {
    GetCities()
      .then((data) => {
        if (data.status) {
          setCitiesList(data.data.data);
        }
      })
      .catch(ErrorHandler);
  }, []);

  const inputClasses: string = "bg-neutral-50 border border-neutral-200";
  return (
    <div className="space-y-2 text-sm sm:text-base">
      <Input
        type="text"
        className={inputClasses}
        required
        name="vehicle_number"
        value={vehicle_number}
        placeholder="Vehicle Number"
        onChange={(e) => dispatch(setVehicleNumber(e.target.value))}
      />
      <InputDatePicker
        date={date === "" ? undefined : new Date(date)}
        onChange={(e) => {
          dispatch(setDate(moment(e).format("YYYY/MM/DD")));
        }}
      />

      <Select
        name="from"
        required
        value={from_city_id}
        onValueChange={(e) => dispatch(setFromCityId(e))}
      >
        <SelectTrigger className="w-full bg-neutral-50 border border-neutral-200">
          <SelectValue placeholder="From City" />
        </SelectTrigger>
        <SelectContent>

          <div className="p-2">
            <input
              type="text"
              placeholder="Search city..."
              value={fromCityFilter}
              onChange={(e) => setFromCityFilter(e.target.value)}
              className="w-full p-2 border border-neutral-300 rounded"
            />
          </div>

          {
            citiesList
              .filter((item) => item.city.toLowerCase().includes(fromCityFilter.toLowerCase()))
              .map((item, i) => {
                return (
                  <SelectItem value={item.id.toString()} key={i}>
                    {item.city}
                  </SelectItem>
                );
              })
          }
        </SelectContent>
      </Select>

      <Select
        name="to"
        required
        value={to_city_id}
        onValueChange={(e) => dispatch(setToCityId(e))}
      >
        <SelectTrigger className="w-full bg-neutral-50 border border-neutral-200">
          <SelectValue placeholder="To City" />
        </SelectTrigger>
        <SelectContent>
          <div className="p-2">
            <input
              type="text"
              placeholder="Search city..."
              value={toCityFilter}
              onChange={(e) => setToCityFilter(e.target.value)}
              className="w-full p-2 border border-neutral-300 rounded"
            />
          </div>
          {
            citiesList
              .filter((item) => item.city.toLowerCase().includes(toCityFilter.toLowerCase()))
              .map((item, i) => {
                return (
                  <SelectItem value={item.id.toString()} key={i}>
                    {item.city}
                  </SelectItem>
                );
              })
          }
        </SelectContent>
      </Select>

      {/* <Select name="orderId">
        <SelectTrigger className={inputClasses}>
          <SelectValue placeholder="Select Order ID" className={inputClasses} />
          <SelectContent className={inputClasses}>
            {orderIds.map((orderId) => (
              <SelectItem key={orderId} value={orderId}>
                {orderId}
              </SelectItem>
            ))}
          </SelectContent>
        </SelectTrigger>
      </Select> */}
    </div>
  );
};

export default BiltyCreateGeneratingForm;
