import React, { useRef, useEffect } from "react";
import { ModalProps } from "../../../lib/definitions";
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import {
  CreateKhataAccountDataType,
  KhataItemDataType,
} from "../../../lib/DataType/KhataDataType";
import { AddNewKataService } from "../../../lib/Service/KhataService";
import { toast } from "react-toastify";
import { ErrorHandler } from "../../../lib/Utils/Common";

const KhataAddAccountModal: React.FC<ModalProps<KhataItemDataType>> = ({ isOpen, onClose, onSubmit }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    const data: CreateKhataAccountDataType = {
      company_name: formData.get("company_name") as string,
      contact_name: formData.get("contact_name") as string,
      mobile_no: Number(formData.get("mobile_no")),
      amount_type: Number(formData.get("amount_type")),
      total_amount: Number(formData.get("total_amount")),
    };

    AddNewKataService(data)
      .then((data) => {
        if (data.status) {
          toast.success(data.message, {
            position: "top-right",
            theme: "colored",
          });
          if (onSubmit) {
            onSubmit(data.data);
          }
        } else {
          toast.error(data.message, {
            position: "top-right",
            theme: "colored",
          });
        }
      })
      .catch(ErrorHandler);
  };

  if (!isOpen) return null;

  const inputClasses: string = "bg-neutral-50";
  return (
    <div className="fixed inset-0 h-[100vh] m-0 bg-black bg-opacity-10 flex items-center justify-center z-50 p-10">
      <div
        className="bg-white rounded-lg shadow-lg w-full max-w-md animate-in fade-in duration-200 p-5"
        ref={modalRef}
      >
        <form onSubmit={handleSubmit} className="space-y-2 text-xs sm:text-sm">
          <div>
            <Label className="text-neutral-600">Name</Label>
            <Input
              required
              name="contact_name"
              placeholder="Name"
              className={inputClasses}
            />
          </div>
          <div>
            <Label className="text-neutral-600">Business Name</Label>
            <Input
              required
              name="company_name"
              placeholder="Business Name"
              className={inputClasses}
            />
          </div>
          <div>
            <Label className="text-neutral-600">Mobile Number</Label>
            <Input
              name="mobile_no"
              placeholder="Mobile Number"
              required
              type="tel"
              className={inputClasses}
            />
          </div>
          <div>
            <Label className="text-neutral-600">Opening Balance</Label>
            <div className="flex items-center gap-2">
              <div className="flex items-center my-2">
                <input
                  name="amount_type"
                  required
                  type="radio"
                  value={1}
                  className={inputClasses}
                />
                <p className="text-xs">To Pay</p>
              </div>
              <div className="flex items-center gap-2">
                <input
                  name="amount_type"
                  required
                  type="radio"
                  value={2}
                  className={inputClasses}
                />
                <p className="text-xs">To Advance</p>
              </div>
            </div>
            <Input
              type="number"
              required
              name="total_amount"
              placeholder="Enter Amount"
              className={inputClasses}
            />
          </div>
          <div className="flex justify-center items-center my-4">
            <Button className="bg-cardinal-600 hover:bg-cardinal-600 w-full max-w-xs rounded-lg">
              Add Account
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default KhataAddAccountModal;
