import BiltyAddItemModal from "../../components/app/modals/BiltyAddItemModal";
import { useEffect, useRef, useState } from "react";
import { Button } from "../../components/ui/button";
import { Plus } from "lucide-react";
import BiltyItemDetailsCard from "../../components/app/cards/BiltyItemDetailsCard";
import BiltyConsignorConsigneeForm from "../../components/app/forms/BiltyConsignorConsigneeForm";
import BiltyFrightDetailsForm from "../../components/app/forms/BiltyFrightDetailsForm";
import {
  BiltyItemDataType,
  ConsignorConsigneeInfoDataType,
} from "../../lib/DataType/BiltyDataType";
import { useDispatch } from "react-redux";
import { addConsignorConsigneeInfo } from "../../store/CreateBiltySlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const BiltyConsignmentPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAddItemModal, setShowAddItemModal] = useState<boolean>(false);
  const [itemDetails, setItemDetails] = useState<BiltyItemDataType[]>([]);
  const [editItem, setEditItem] = useState<number>(-1);

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (formRef.current) {
      (formRef.current["consignor_name"] as HTMLInputElement).value = "JohnDoe";
    }
  }, []);

  const toggleAddItemModal = () => {
    setShowAddItemModal((prev) => !prev);
  };

  const deleteItem = (index: number) => {
    setItemDetails((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  const onSetItem = (item: BiltyItemDataType) => {
    if (editItem < 0) {
      setItemDetails((itemDetails) => itemDetails.concat(item));
    } else {
      setItemDetails((itemDetails) => {
        itemDetails[editItem] = item;
        return itemDetails;
      });
    }
    setShowAddItemModal(false);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (itemDetails.length <= 0) {
      toast.error("Please add at least one item");
      return;
    }
    if (formRef.current) {
      const reqData: ConsignorConsigneeInfoDataType = {
        consignee_address: formRef.current["consignee_address"].value,
        consignee_gst_number: formRef.current["consignee_gst_number"].value,
        consignee_name: formRef.current["consignee_name"].value,
        consignee_mobile_no: formRef.current["consignee_mobile_no"].value,

        consignor_address: formRef.current["consignor_address"].value,
        consignor_gst_number: formRef.current["consignor_gst_number"].value,
        consignor_mobile_no: formRef.current["consignor_mobile_no"].value,
        consignor_name: formRef.current["consignor_name"].value,

        freight: formRef.current["freight"].value,
        advance_amount: formRef.current["advance_amount"].value,
        remaining_amount: formRef.current["remaining_amount"].value,
        total_amount: formRef.current["total_amount"].value,
        item: itemDetails,
      };

      dispatch(addConsignorConsigneeInfo(reqData));
      navigate("/bilty/create");
    }
  };
  return (
    <div className="w-full px-5 mb-10">
      <form ref={formRef} onSubmit={onSubmit}>
        <div className="w-full my-4">
          <BiltyConsignorConsigneeForm />
        </div>
        <div className="my-4">
          <h3 className="font-semibold sm:text-lg">Items</h3>
          <div className="min-h-40 grid sm:grid-cols-2 gap-5 lg:grid-cols-3 items-center my-3">
            {itemDetails.map((item, index) => (
              <BiltyItemDetailsCard
                key={index}
                {...item}
                onDelete={() => deleteItem(index)}
                onEdit={() => {
                  setEditItem(index);
                  setShowAddItemModal(true);
                }}
              />
            ))}
            <div className="flex items-center justify-center min-h-40">
              <Button
                onClick={toggleAddItemModal}
                type="button"
                className="bg-cardinal-600 p-5 rounded-full text-white hover:bg-cardinal-600 shadow-none"
              >
                <Plus size={40} />
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full py-4">
          <BiltyFrightDetailsForm />
        </div>

        <div className="w-full flex justify-center items-center">
          <Button
            className="bg-cardinal-600 hover:bg-cardinal-600 text-sm sm:text-base w-full max-w-[16rem]"
            type="submit"
          >
            SUBMIT
          </Button>
        </div>
      </form>
      {showAddItemModal && (
        <BiltyAddItemModal
          isOpen={showAddItemModal}
          onClose={toggleAddItemModal}
          onSubmit={onSetItem}
          item={itemDetails[editItem]}
        />
      )}
    </div>
  );
};
export default BiltyConsignmentPage;
