import { ResListDataType } from "../DataType/ApiCommon";
import { CitieDataType, StateDataType } from "../DataType/MasterDataType";
import { MASTER_URL } from "../Utils/EnvVariables";
import HttpClient from "../Utils/HttpClient";

export const GetStates = (): Promise<ResListDataType<StateDataType[]>> => {
  return HttpClient.get(`${MASTER_URL}/states`);
};

export const GetCities = (): Promise<ResListDataType<CitieDataType[]>> => {
  return HttpClient.get(`${MASTER_URL}/cities`);
};
