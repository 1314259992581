import { Separator } from "../../ui/separator";
import { Pencil, Trash2 } from "lucide-react";
import BiltyEditConsignmentModal from "../modals/bilty-edit-consignment-modal";
import { useState } from "react";

export interface BiltyCreateConsignmentDetailsCardProps {
  consignor: string;
  cosignee: string;
  totalItems: number | string;
  remaining: number | string;
}

export default function BiltyCreateConsignmentDetailsCard({
  ...props
}: BiltyCreateConsignmentDetailsCardProps) {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const toggleModal = () => {
    setShowEditModal((prev) => !prev);
  };

  return (
    <div className="p-2 space-y-1 bg-white rounded-md shadow text-xs max-w-xs">
      <div className="flex justify-between gap-3">
        <div className="space-y-1">
          <p className="text-neutral-400">CONSIGNOR</p>
          <p className="font-medium">{props.consignor}</p>
        </div>
        <div className="flex gap-2">
          <Pencil
            size={16}
            className="text-blue-500 cursor-pointer"
            onClick={toggleModal}
          />
          <Trash2 size={16} className="text-cardinal-600 cursor-pointer" />
        </div>
      </div>
      <Separator className="bg-neutral-200" />
      <div className="space-y-1">
        <p className="text-neutral-400">CONSIGNEE</p>
        <p className="font-medium">{props.cosignee}</p>
      </div>
      <Separator className="bg-neutral-200" />
      <div className="flex items-center gap-10 justify-between">
        <div className="space-y-1">
          <p className="text-neutral-400">TOTAL ITEMS</p>
          <p className="font-medium">{props.totalItems}</p>
        </div>
        <div className="space-y-1">
          <p className="text-neutral-400">REMAINING</p>
          <p className="font-medium">{props.remaining}</p>
        </div>
      </div>
      <BiltyEditConsignmentModal
        isOpen={showEditModal}
        onClose={toggleModal}
        {...props}
      />
    </div>
  );
}
