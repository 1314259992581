import { ResDataType, ResListDataType } from "../DataType/ApiCommon";
import {
  BiltyDataType,
  BiltyDetailsDataType,
  BiltyListDataType,
  BiltySettingDataDatType,
} from "../DataType/BiltyDataType";
import { TRANSPORTER_URL } from "../Utils/EnvVariables";
import HttpClient from "../Utils/HttpClient";

export const BiltySettingUpdate = (
  request: BiltySettingDataDatType,
): Promise<ResDataType<BiltySettingDataDatType>> => {
  return HttpClient.post(`${TRANSPORTER_URL}/bilty/bilty-setting`, request);
};

export const BiltySettingGet = (): Promise<
  ResDataType<BiltySettingDataDatType>
> => {
  return HttpClient.get(`${TRANSPORTER_URL}/bilty/bilty-setting`);
};

export const CreateBiltyService = (
  request: BiltyDataType,
): Promise<ResDataType<BiltyDataType>> => {
  return HttpClient.post(`${TRANSPORTER_URL}/bilty/create`, request);
};

export const GetBiltyListService = (pramitar?: string): Promise<
  ResListDataType<BiltyListDataType[]>
> => {
  return HttpClient.get(`${TRANSPORTER_URL}/bilty/list?${pramitar}`);
};

export const GetBiltyDetailsService = (
  id: string | number,
): Promise<ResListDataType<BiltyDetailsDataType>> => {
  return HttpClient.get(`${TRANSPORTER_URL}/bilty/${id}/show`);
};
