import BiltyCreateGeneratingForm from "../../components/app/forms/BiltyCreateGeneratingForm";
import BiltyTruckDetailsModal from "../../components/app/modals/BiltyTruckDetailsModal";
import { useState } from "react";
import { Separator } from "../../components/ui/separator";
import { ChevronRight, Plus } from "lucide-react";
import { Input } from "../../components/ui/input";
import BiltyCreateConsignmentDetailsCard from "../../components/app/cards/bilty-create-consignment-details-card";
import { Link, useNavigate } from "react-router-dom";
import BiltyInvoiceDetailsForm from "../../components/app/forms/BiltyInvoiceDetailsForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { Button } from "../../components/ui/button";
import { setBiltyCommission } from "../../store/CreateBiltySlice";
import { BiltyDataType } from "../../lib/DataType/BiltyDataType";
import { toast } from "react-toastify";
import { CreateBiltyService } from "../../lib/Service/BiltyService";
import { ErrorHandler, IsExpiryDateValid } from "../../lib/Utils/Common";

const CreateBiltyPage = () => {
  const {
    consignor_consignee_info,
    bilty_commission,
    date,
    driver_name,
    driver_number,
    e_way_bill_number,
    expiry_date,
    fields,
    fleet_owner,
    fleet_owner_number,
    from_city_id,
    gst_number,
    invoice_date,
    invoice_generation_date,
    invoice_number,
    license_number,
    lr_bilty_number,
    order_id,
    remark,
    to_city_id,
    vehicle_number,
  } = useSelector((state: RootState) => state.createBilty);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showTruckDetailsModal, setShowTruckDetailsModal] =
    useState<boolean>(false);

  const toggleTruckDetailsModal = () => {
    setShowTruckDetailsModal((prev) => !prev);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (consignor_consignee_info.length <= 0) {
      toast.error("Please add at least one Consignment Details");
      return;
    }
    if (!IsExpiryDateValid(invoice_generation_date, expiry_date)) {
      toast.error(
        "The expiry date must be a date after invoice generation date.",
      );
      return;
    }
    const reqData: BiltyDataType = {
      bilty_commission,
      consignor_consignee_info,
      date,
      driver_name,
      driver_number,
      e_way_bill_number,
      expiry_date,
      fields,
      fleet_owner,
      fleet_owner_number,
      from_city_id,
      gst_number,
      invoice_date,
      invoice_generation_date,
      invoice_number,
      license_number,
      lr_bilty_number,
      order_id,
      remark,
      to_city_id,
      vehicle_number,
    };
    CreateBiltyService(reqData)
      .then((data) => {
        if (data.status) {
          toast.success(data.message, {
            position: "top-right",
            theme: "colored",
          });
          console.log("data", data);
          navigate("/bilty");
        }
      })
      .catch(ErrorHandler);
  };

  const inputClasses: string =
    "w-full bg-neutral-50 border border-neutral-200 text-neutral-800";

  return (
    <div className="w-full px-5 mb-10">
      <div>
        <h3 className="font-semibold sm:text-lg">Generating</h3>
      </div>
      <form onSubmit={onSubmit}>
        <div className="pt-3 w-full max-w-xl">
          <BiltyCreateGeneratingForm />
        </div>
        <div className="my-4">
          <Separator />
        </div>
        <div className="space-y-3 text-sm sm:text-base w-full">
          <div
            className="bg-neutral-50 w-full cursor-pointer border border-neutral-200 rounded-md px-3 py-2 max-w-2xl flex items-center justify-between gap-2 text-sm sm:text-base"
            onClick={toggleTruckDetailsModal}
          >
            <p className="font-medium">Truck Details</p>
            <ChevronRight size={16} className="text-neutral-600" />
          </div>
          <div className="bg-neutral-50 border border-neutral-200 w-full rounded-md px-3 py-2 max-w-2xl flex flex-col gap-2 text-sm sm:text-base">
            <Link
              to="/bilty/create/consigment"
              className="flex justify-between gap-3"
            >
              <p className="font-medium text-sm sm:text-base truncate">
                Consignment Details
              </p>
              <div className="flex items-center gap-2">
                <div className="px-1 py-0.5 rounded-sm text-xs flex bg-blue-200 items-center">
                  <Plus size={16} className="text-neutral-600 " />
                  <p className="text-xs ">Consignor & Consignee</p>
                </div>
                <ChevronRight size={16} className="text-neutral-600" />
              </div>
            </Link>
            {/* can make changes in grid here if want to show more than one  */}
            <div className="px-3 py-2 gap-3 flex overflow-x-auto whitespace-nowrap">
              {consignor_consignee_info.map((item, i) => {
                return (
                  <BiltyCreateConsignmentDetailsCard
                    consignor={item.consignor_name}
                    cosignee={item.consignee_name}
                    remaining={item.remaining_amount}
                    totalItems={item.total_amount}
                    key={i}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className="w-full my-4 max-w-xl">
          <h3 className="font-semibold sm:text-lg my-3">Bilty Commission</h3>
          <Input
            type="text"
            name="biltyPrice"
            placeholder="Bilty Price"
            className={inputClasses}
            value={bilty_commission}
            onChange={(e) => dispatch(setBiltyCommission(e.target.value))}
          />
        </div>
        <div className="w-full py-4">
          <BiltyInvoiceDetailsForm />
        </div>
        <div className="w-full flex justify-center items-center">
          <Button
            className="bg-cardinal-600 hover:bg-cardinal-600 text-sm sm:text-base w-full max-w-[16rem]"
            type="submit"
          >
            SUBMIT
          </Button>
        </div>
      </form>
      <BiltyTruckDetailsModal
        isOpen={showTruckDetailsModal}
        onClose={toggleTruckDetailsModal}
      />
      {/*
      {itemDetails.map((item, index) => (
        <ItemDetailsCard key={index} {...item} />
      ))} */}
    </div>
  );
};

export default CreateBiltyPage;
