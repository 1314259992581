import { useRef, useEffect } from "react";
import { Label } from "../../ui/label";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "../../ui/input-otp";
import { REGEXP_ONLY_DIGITS } from "input-otp";
import { useNavigate } from "react-router-dom";
import { Button } from "../../ui/button";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
const VerifyEmailOtpModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 h-[100vh] m-0 bg-black bg-opacity-10 flex items-center justify-center z-50 p-4">
      <div
        className="bg-white rounded-lg w-full max-w-md shadow-lg"
        ref={modalRef}
      >
        <div className="flex flex-col space-y-3 p-5">
          <div className="w-full text-center">
            <Label className="text-sm sm:text-base md:text-lg">Enter OTP</Label>
          </div>

          <InputOTP maxLength={6} pattern={REGEXP_ONLY_DIGITS}>
            <InputOTPGroup className="w-full justify-between">
              <InputOTPSlot index={0} className="" />
              <InputOTPSlot index={1} />
              <InputOTPSlot index={2} />
              <InputOTPSlot index={3} />
              <InputOTPSlot index={4} />
              <InputOTPSlot index={5} />
            </InputOTPGroup>
          </InputOTP>
          <div className="flex justify-center items-center">
            {/* add logic here acc for incorrect otp */}
            <Button
              type="submit"
              onClick={() => navigate("/signup/verification/profile3")}
              className="w-full bg-cardinal-700 hover:bg-cardinal-700"
            >
              Verify OTP
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailOtpModal;
