import BusinessProfileParent from "../../../components/app/business-profile-parent";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "../../../components/ui/button";
import SuccessModal from "../../../components/app/modals/success-modal";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { useDispatch, useSelector } from "react-redux";
import {
  CitieDataType,
  StateDataType,
} from "../../../lib/DataType/MasterDataType";
import { RootState } from "../../../store";
import { GetCities, GetStates } from "../../../lib/Service/MasterService";
import { ErrorHandler } from "../../../lib/Utils/Common";
import { UpdateProfileService } from "../../../lib/Service/AuthService";
import { setUser } from "../../../store/userSlice";
import { toast } from "react-toastify";

const BusinessProfile3 = () => {
  const { useData, isLogin } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const [stateList, setStateList] = useState<StateDataType[]>([]);
  const [citiesList, setCitiesList] = useState<CitieDataType[]>([]);

  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    GetStates()
      .then((data) => {
        if (data.status) {
          setStateList(data.data.data);
        }
      })
      .catch(ErrorHandler);

    GetCities()
      .then((data) => {
        if (data.status) {
          setCitiesList(data.data.data);
        }
      })
      .catch(ErrorHandler);

    if (useData && formRef.current) {
      (formRef.current["shop_no"] as HTMLInputElement).value =
        useData.shop_no || "";
      (formRef.current["area"] as HTMLInputElement).value = useData.area || "";
      (formRef.current["state_id"] as HTMLInputElement).value =
        useData.state_id?.toString() || "";
      (formRef.current["city_id"] as HTMLInputElement).value =
        useData.city_id?.toString() || "";
    }
  }, [useData]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData.entries());

    console.log(data);

    UpdateProfileService(data)
      .then((data) => {
        if (data.status) {
          dispatch(setUser(data.data));
          toast.success(data.message, {
            position: "top-right",
            theme: "colored",
          });
          // navigate('/')
          setShowModal((prev) => !prev);
          // setIsActive(false);
        } else {
          ErrorHandler(data as any);
        }
      })
      .catch(ErrorHandler);
  };

  const toggleShowModal = (isContinue?: boolean) => {
    setShowModal((prev) => !prev);
    if (isContinue && isLogin) {
      window.location.href = "/";
    }
  };

  return (
    <BusinessProfileParent>
      <div className="w-full mb-10 sm:h-[70vh] flex justify-center items-center p-5 pt-0 sm:pt-5">
        <div className="bg-cardinal-300/30  bg-opacity-45 p-6 rounded-lg max-w-sm md:max-w-md w-full">
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-lg font-semibold">Business Profile</h2>
            <p className="text-cardinal-500">
              <span className="text-xl sm:text-2xl">3</span>
              <span className="text-neutral-900">/3</span>
              <div className="text-xs text-neutral-900">Completed</div>
            </p>
          </div>

          <div className="space-y-2">
            <h3 className="text-cardinal-500 font-medium mb-4">Address Info</h3>

            <form onSubmit={handleSubmit} ref={formRef} className="space-y-1">
              <div>
                <Label className="text-xs text-neutral-400">
                  Shop No./ Factory
                </Label>
                <Input
                  type="text"
                  name="shop_no"
                  placeholder="Shop No./ Factory"
                  className="w-full rounded-md border bg-whiye shadow-none border-neutral-200 focus:outline-none focus:ring-2 focus:ring-cardinal-400"
                />
              </div>

              <div>
                <Label className="text-xs text-neutral-400">Area</Label>
                <Input
                  type="text"
                  name="area"
                  placeholder="Area"
                  className="w-full rounded-md border bg-whiye shadow-none border-neutral-200 focus:outline-none focus:ring-2 focus:ring-cardinal-400"
                />
              </div>
              <div>
                <Label className="text-xs text-neutral-400">State</Label>
                <div className="relative">
                  <Select name="state_id">
                    <SelectTrigger className="w-full bg-white">
                      <SelectValue placeholder="State" />
                    </SelectTrigger>
                    <SelectContent>
                      {stateList?.map((item, i) => {
                        return (
                          <SelectItem value={item.id.toString()} key={i}>
                            {item.name}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <div>
                <Label className="text-xs text-neutral-400">City</Label>
                <div className="relative">
                  <Select name="city_id">
                    <SelectTrigger className="w-full bg-white">
                      <SelectValue placeholder="City" />
                    </SelectTrigger>
                    <SelectContent>
                      {citiesList.map((item, i) => {
                        return (
                          <SelectItem value={item.id.toString()} key={i}>
                            {item.city}
                          </SelectItem>
                        );
                      })}
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <div className="pt-4 flex justify-end">
                <Button
                  type="submit"
                  className="w-full max-w-[13rem] bg-cardinal-600 text-white py-3 rounded-md hover:bg-cardinal-600 transition-colors"
                >
                  Go To Dashboard
                </Button>
              </div>
            </form>
          </div>
        </div>
        {showModal && <SuccessModal closeModal={toggleShowModal} />}
      </div>
    </BusinessProfileParent>
  );
};

export default BusinessProfile3;
