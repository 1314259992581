import { ResDataType } from "../DataType/ApiCommon";
import { MAIN_BASE_URL } from "./EnvVariables";

// Define the base URL for API requests
const BASE_URL = `${MAIN_BASE_URL}`;

/**
 * Retrieves the authentication token from local storage,
 * or uses a default token if not available.
 *
 * @returns {string} The authentication token.
 */
function getToken(): string {
  const token = localStorage.getItem("token");
  return token || "";
}

// Define a type for request parameters, allowing flexible key-value pairs
interface RequestParams {
  [key: string]: any;
}

/**
 * Executes a GET request with optional query parameters.
 *
 * @param {string} endpoint - The API endpoint to request.
 * @param {RequestParams} [params] - Optional query parameters.
 * @returns {Promise<any>} A promise that resolves with the response data.
 */
function get(endpoint: string, params?: RequestParams): Promise<any> {
  return request(endpoint, params, "GET");
}

/**
 * Executes a POST request with a request body.
 *
 * @param {string} endpoint - The API endpoint to request.
 * @param {RequestParams} params - The request body parameters.
 * @returns {Promise<any>} A promise that resolves with the response data.
 */
function post(endpoint: string, params: RequestParams): Promise<any> {
  return request(endpoint, params, "POST");
}

/**
 * Executes a PUT request with a request body.
 *
 * @param {string} endpoint - The API endpoint to request.
 * @param {RequestParams} params - The request body parameters.
 * @returns {Promise<any>} A promise that resolves with the response data.
 */
function put(endpoint: string, params: RequestParams): Promise<any> {
  return request(endpoint, params, "PUT");
}

/**
 * Executes a PATCH request with a request body.
 *
 * @param {string} endpoint - The API endpoint to request.
 * @param {RequestParams} params - The request body parameters.
 * @returns {Promise<any>} A promise that resolves with the response data.
 */
function patch(endpoint: string, params: RequestParams): Promise<any> {
  return request(endpoint, params, "PATCH");
}

/**
 * Executes a DELETE request with optional query parameters or a request body.
 *
 * @param {string} endpoint - The API endpoint to request.
 * @param {RequestParams} [params] - Optional request parameters or body.
 * @returns {Promise<any>} A promise that resolves with the response data.
 */
function Delete(endpoint: string, params?: RequestParams): Promise<any> {
  return request(endpoint, params, "DELETE");
}

/**
 * Sends a request to the specified endpoint using the given HTTP method.
 *
 * @param {string} endpoint - The API endpoint to request.
 * @param {RequestParams} [params] - Optional parameters for query or body.
 * @param {string} [method='GET'] - The HTTP method (GET, POST, PUT, DELETE).
 * @returns {Promise<any>} A promise that resolves with the response data.
 */
async function request(
  endpoint: string,
  params: RequestParams = {},
  method: string = "GET",
): Promise<any> {
  const xhr: XMLHttpRequest = new XMLHttpRequest();
  xhr.open(method, `${BASE_URL + endpoint}`);
  const token = getToken();
  if (token !== "") {
    xhr.setRequestHeader("authorization", `Bearer ${token}`);
  }
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.setRequestHeader("Accept-Language", "hi");

  return new Promise((resolve, reject) => {
    if (method === "GET") {
      xhr.send();
    } else {
      xhr.send(JSON.stringify(params));
    }
    xhr.onload = () => {
      const response = JSON.parse(xhr.response);

      if (xhr.status === 200) {
        resolve(response);
      } else if (xhr.status === 401) {
        reject(response);
        // window.location.href = "/"
      } else {
        reject(response);
      }
    };
  });
}

/**
 * Uploads a file to the specified endpoint.
 *
 * @param {string} endpoint - The API endpoint to upload the file to.
 * @param {File} file - The file object to be uploaded.
 * @param {string} fildName - The fildName to be uploaded fikd name.
 * @returns {Promise<any>} A promise that resolves with the response data or rejects with an error.
 */
async function FileUpload(
  endpoint: string,
  file: File,
  fildName: string = "image",
): Promise<ResDataType<any>> {
  const token = getToken();
  const url = `${BASE_URL}${endpoint}`;
  const formData = new FormData();
  formData.append(fildName, file);

  const options: RequestInit = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      userType: "User",
    },
    body: formData,
  };

  return fetch(url, options)
    .then(async (response) => {
      if (!response.ok) {
        const errorResponse = await response.json();
        return {
          error: `Upload failed with status ${response.status}`,
          response: errorResponse,
        };
      }
      return response.json();
    })
    .catch((error) => {
      console.error("File upload failed", error);
      return { error: "Upload error", details: error };
    });
}

export const HttpClient = {
  get,
  post,
  put,
  Delete,
  patch,
  FileUpload,
};

export default HttpClient;
