import React, { useState, useEffect, useRef } from "react";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import { Label } from "../../ui/label";
import { CircleX } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { setTruckDetails } from "../../../store/CreateBiltySlice";
import { RootState } from "../../../store";
import {
  ValidateGSTNumber,
  ValidateLicenseNumber,
  ValidateVehicleNumber,
} from "../../../lib/Utils/Common";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const BiltyTruckDetailsModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  const {
    driver_name,
    driver_number,
    fleet_owner,
    gst_number,
    license_number,
    vehicle_number,
  } = useSelector((state: RootState) => state.createBilty);

  const dispatch = useDispatch();
  const [vehicleNumber, setVehicleNumber] = useState(vehicle_number);
  const [driverName, setDriverName] = useState(driver_name);
  const [driverNumber, setDriverNumber] = useState(driver_number);
  const [licenseNumber, setLicenseNumber] = useState(license_number);
  const [fleetOwner, setFleetOwner] = useState(fleet_owner);
  const [gstNumber, setGstNumber] = useState(gst_number);

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(
      setTruckDetails({
        driver_name: driverName,
        driver_number: driverNumber,
        fleet_owner: fleetOwner,
        gst_number: gstNumber,
        license_number: licenseNumber,
        vehicle_number: vehicleNumber,
      }),
    );

    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 h-[100vh] m-0 bg-black bg-opacity-10 flex items-center justify-center z-50 p-10">
      <div
        className="bg-white rounded-lg shadow-lg w-full max-w-md animate-in fade-in duration-200"
        ref={modalRef}
      >
        <form className="space-y-3 p-5" onSubmit={handleSubmit}>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Vehicle Number
            </Label>
            <div className="relative w-full">
              <Input
                required
                value={vehicleNumber}
                onChange={(e) => setVehicleNumber(e.target.value)}
                type="text"
                placeholder="Vehicle Number"
                // className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
                className={`w-full bg-neutral-50 border text-sm text-neutral-800 ${!ValidateVehicleNumber(vehicleNumber) ? "border-red-500" : "border-neutral-200"}`}
              />
              {vehicleNumber !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => setVehicleNumber("")}
                />
              )}
            </div>
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Driver Name
            </Label>
            <div className="relative w-full">
              <Input
                required
                value={driverName}
                onChange={(e) => setDriverName(e.target.value)}
                type="text"
                placeholder="Enter Driver Name"
                className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
              />
              {driverName !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => setDriverName("")}
                />
              )}
            </div>
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Driver Number
            </Label>
            <div className="relative w-full">
              <Input
                required
                value={driverNumber}
                onChange={(e) => setDriverNumber(e.target.value)}
                type="text"
                placeholder="Enter Driver Number"
                className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
              />
              {driverNumber !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => setDriverNumber("")}
                />
              )}
            </div>
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              License Number
            </Label>
            <div className="relative w-full">
              <Input
                required
                value={licenseNumber}
                onChange={(e) => setLicenseNumber(e.target.value)}
                type="text"
                placeholder="Enter License Number"
                // className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
                className={`w-full bg-neutral-50 border text-sm text-neutral-800 ${!ValidateLicenseNumber(licenseNumber) ? "border-red-500" : "border-neutral-200"}`}
              />
              {licenseNumber !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => setLicenseNumber("")}
                />
              )}
            </div>
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Fleet Owner
            </Label>
            <div className="relative w-full">
              <Input
                required
                value={fleetOwner}
                onChange={(e) => setFleetOwner(e.target.value)}
                type="text"
                placeholder="Enter Owner Name"
                className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
              />
              {fleetOwner !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => setFleetOwner("")}
                />
              )}
            </div>
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              GST Number
            </Label>
            <div className="relative w-full">
              <Input
                required
                value={gstNumber}
                onChange={(e) => setGstNumber(e.target.value)}
                type="text"
                placeholder="Enter GST Number"
                className={`w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800 ${!ValidateGSTNumber(gstNumber) ? "border-red-500" : "border-neutral-200"}`}
              />
              {gstNumber !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => setGstNumber("")}
                />
              )}
            </div>
          </div>
          <div className="flex justify-center items-center pt-4">
            <Button
              className="bg-cardinal-600 w-24 sm:w-full sm:max-w-[15rem] hover:bg-cardinal-600"
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BiltyTruckDetailsModal;
