import { Pencil, ArrowDownToLine, Eye } from "lucide-react";
import { Button } from "../../ui/button";
import React, { useState } from "react";
import KhataStatementFilterModal from "../modals/khata-statement-filter-modal";

export interface KhataAccountDetailsProps {
  name: string;
  bussinessName: string;
  mobileNumber: string;
  clickEdit: (action: boolean) => void
}

const KhataAccountDetailsCard: React.FC<KhataAccountDetailsProps> = ({ bussinessName, mobileNumber, name, clickEdit }) => {
  const [showStatementFilterModal, setShowStatementFilterModal] = useState<boolean>(false);
  const toggleStatementFilterModal = () => {
    setShowStatementFilterModal((prev) => !prev);
  };

  return (
    <div className="w-full p-3 shadow-sm border rounded-md flex flex-col gap-4 text-xs sm:text-sm">
      <div className="flex gap-2 items-center justify-between">
        <div className="flex items-center gap-2">
          <h3 className="font-semibold sm:text-lg">{name}</h3>
          <Pencil
            size={16}
            className="text-blue-600 cursor-pointer"
            onClick={() => clickEdit(true)}
          />
        </div>
        <div className="flex items-center gap-2">
          <Button
            className="rounded-full p-2.5 flex items-center justify-center text-white bg-orange-500 hover:bg-orange-500 shadow-none"
            onClick={toggleStatementFilterModal}
          >
            <Eye size={16} />
          </Button>
          <Button className="rounded-full p-2.5 flex items-center justify-center text-white bg-green-600 hover:bg-green-600 shadow-none">
            <ArrowDownToLine size={16} />
          </Button>
        </div>
      </div>
      <div className="flex items-center gap-2 text-neutral-400 text-xs sm:text-sm">
        <p className="truncate">{bussinessName}</p>
        <div className="border" />
        <p>{mobileNumber}</p>
      </div>
      <KhataStatementFilterModal
        isOpen={showStatementFilterModal}
        onClose={toggleStatementFilterModal}
      />
    </div>
  );
}


export default KhataAccountDetailsCard;