import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/auth/LoginPage";
import Homepage from "./pages/Homepage";
import LoginVerificationPage from "./pages/auth/LoginVerificationPage";
import SignUpPage from "./pages/auth/SignupPage";
import SignupVerificationPage from "./pages/auth/SignupVerificationPage";
import BusinessProfile1 from "./pages/auth/profile/BussinessProfile1";
import BusinessProfile2 from "./pages/auth/profile/BusinessProfile2";
import BusinessProfile3 from "./pages/auth/profile/BusinessProfile3";
import MainLayout from "./components/app/main-layout";
import ChatPage from "./pages/ChatPage";
import BiltyPage from "./pages/bilty";
import LoadsPage from "./pages/LoadsPage";
import KhataPage from "./pages/KhataPage";
import AboutPage from "./pages/AboutPage";
import HelpPage from "./pages/HelpPage";
import FaqsPage from "./pages/FaqsPage";
import PrivacyPage from "./pages/PrivacyPage";
import CreateBiltyPage from "./pages/bilty/CreateBiltyPage";
import BiltyDetailsPage from "./pages/bilty/BiltyDetailsPage";
import BiltyConsignmentPage from "./pages/bilty/BiltyConsignmentPage";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import "react-toastify/dist/ReactToastify.css";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { ToastContainer } from "react-toastify";

const App: React.FC = () => {
  const User = useSelector((state: RootState) => state.user);
  return (
    <BrowserRouter>
      <Routes>
        {
          User.isLogin ?
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Homepage />} />
            <Route path="chat" element={<ChatPage />} />
            <Route path="bilty" element={<BiltyPage />} />
            <Route path="bilty/create" element={<CreateBiltyPage />} />
            <Route
              path="bilty/create/consigment"
              element={<BiltyConsignmentPage />}
            />
            <Route path="bilty/:id/details/" element={<BiltyDetailsPage />} />
            <Route path="loads" element={<LoadsPage />} />
            <Route path="khata" element={<KhataPage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="help" element={<HelpPage />} />
            <Route path="faqs" element={<FaqsPage />} />
            <Route path="privacy" element={<PrivacyPage />} />
          </Route>
          :
          <Route path="/">
            <Route index element={<LoginPage />} />
            <Route path="/login/verification" element={<LoginVerificationPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route
              path="/signup/verification"
              element={<SignupVerificationPage />}
            />
          </Route>
        }
        <Route
          path="/signup/verification/profile1"
          element={<BusinessProfile1 />}
        />
        <Route
          path="/signup/verification/profile2"
          element={<BusinessProfile2 />}
        />
        <Route
          path="/signup/verification/profile3"
          element={<BusinessProfile3 />}
        />

        <Route path="*" element={<div>Not Found</div>} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;
