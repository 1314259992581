import { Link } from "react-router-dom";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { Settings, Search, SlidersHorizontal, Plus } from "lucide-react";
import { Label } from "../../components/ui/label";
import BiltyFilterModal from "../../components/app/modals/bilty-filter-modal";
import BiltySettingModal from "../../components/app/modals/bilty-setting-modal";
import { useEffect, useState } from "react";
import { BiltyListDataType } from "../../lib/DataType/BiltyDataType";
import BiltyCard from "../../components/app/cards/bilty-card";
import { GetBiltyListService } from "../../lib/Service/BiltyService";
import { ErrorHandler } from "../../lib/Utils/Common";

const BiltyPage = () => {
  const [searchParams, setSearchParams] = useState<string>("");
  const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
  const [showSettingModal, setShowSettingModal] = useState<boolean>(false);
  const [biltyList, setBiltyList] = useState<BiltyListDataType[]>([]);

  const toggleFilterModal = () => {
    setShowFilterModal((prev) => !prev);
  };

  const toggleSettingModal = () => {
    setShowSettingModal((prev) => !prev);
  };

  useEffect(() => {
    GetBiltyListService()
      .then((data) => {
        if (data.status) {
          setBiltyList(data.data.data);
        }
      })
      .catch(ErrorHandler);
  }, []);

  return (
    <div className="w-full mb-10">
      <div
        className="px-5 flex flex-col sm:flex-row sm:justify-between items-center gap-2"
      >
        <div className="relative w-full">
          <Label htmlFor="search">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-neutral-400 h-4 w-4" />
          </Label>
          <Input
            placeholder="Search"
            id="search"
            name="search"
            value={searchParams}
            onChange={(e) => setSearchParams(e.target.value)}
            className="pl-9 pr-4 h-10 shadow-none border rounded-md w-full"
          />
        </div>

        <div className="flex justify-end sm:justify-normal w-full sm:w-fit">
          <div className="flex items-center gap-3">
            <Link to="/bilty/create">
              <Button className="bg-cardinal-500 sm:w-32 hover:bg-cardinal-600 text-white px-6 h-10 rounded-lg">
                ADD
              </Button>
            </Link>
            <Button
              variant="ghost"
              size="icon"
              className="h-10 w-10 bg-neutral-100 rounded-full"
              onClick={toggleFilterModal}
            >
              <SlidersHorizontal className="h-5 w-5 text-cardinal-500" />
            </Button>
            <Button
              variant="ghost"
              size="icon"
              className="h-10 w-10 bg-neutral-100 rounded-full"
              onClick={toggleSettingModal}
            >
              <Settings className="h-5 w-5 text-cardinal-500" />
            </Button>
          </div>
        </div>
      </div>

      {biltyList.length > 0 ? (
        <div className="space-y-4 px-5 pt-5">
          <h3 className="font-bold text-lg sm:text-2xl">Bilty</h3>
          <div className="grid gap-5 md:grid-cols-2">
            {
              biltyList.map((bilty, i) => (
                <BiltyCard
                  key={i}
                  date={new Date(bilty.date)}
                  from={bilty.from_city}
                  fromLocation={bilty.from_city}
                  lrNumber={bilty.lr_bilty_number}
                  orders={Number(bilty.order_count)}
                  status={bilty.status}
                  to={bilty.to_city}
                  id={bilty.id}
                  toLocation={bilty.to_city}
                  vehicleNumber={bilty.vehicle_number}
                />
              ))
            }
          </div>
        </div>
      ) : (
        <>
          <div className="min-h-[30vh] flex justify-center items-end">
            <div className="space-y-2">
              <div className="flex justify-center">
                <Link to="/bilty/create">
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-10 w-10 bg-cardinal-600 hover:bg-cardinal-600 rounded-full"
                  >
                    <Plus className="h-5 w-5 text-white" />
                  </Button>
                </Link>
              </div>
              <p className="text-center font-bold">Add your first Bilty</p>
            </div>
          </div>

          <div className="flex  justify-between items-center px-5 gap-5">
            <img
              src="/bilty/bilty-1.png"
              className="h-[9rem] sm:h-[12rem] md:h-[16rem] lg:h-[20rem]"
              alt="bilty 1"
            />
            <img
              src="/bilty/bilty-2.png"
              className="h-[9rem] sm:h-[12rem] md:h-[16rem] lg:h-[20rem]"
              alt="bilty 2"
            />
          </div>
        </>
      )}

      <BiltyFilterModal isOpen={showFilterModal} onClose={toggleFilterModal} />
      <BiltySettingModal
        isOpen={showSettingModal}
        onClose={toggleSettingModal}
      />
    </div>
  );
};

export default BiltyPage;
