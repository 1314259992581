import { useRef, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { Menu } from "lucide-react";

interface SidebarProps {
  isOpen: boolean;
  setIsOpen: (arg: boolean | ((prev: boolean) => boolean)) => void;
  isLargeScreen: boolean;
}

function Sidebar({ isOpen, setIsOpen, isLargeScreen }: SidebarProps) {
  const sidebarRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node) &&
        !isLargeScreen
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen, isLargeScreen]);

  const toggleSidebar = () => {
    if (!isLargeScreen) {
      setIsOpen((prev) => !prev);
    }
  };

  const menuItems = [
    { icon: "/home/home-sidebar.svg", label: "Home", path: "/" },
    // { icon: "/home/chat-sidebar.svg", label: "Chat", path: "/chat" },
    { icon: "/home/bilty-sidebar.svg", label: "Bilty", path: "/bilty" },
    // { icon: "/home/loads.svg", label: "Loads", path: "/loads" },
    { icon: "/home/khata.svg", label: "Khata", path: "/khata" },
  ];

  const bottomMenuItems = [
    { label: "About", path: "/about" },
    { label: "Help", path: "/help" },
    { label: "FAQs", path: "/faqs" },
    { label: "Privacy", path: "/privacy" },
  ];

  return (
    <>
      {!isLargeScreen && (
        <div className="fixed top-0 left-0 z-50 p-2 pt-[17px]">
          <Menu
            className="text-neutral-900 cursor-pointer"
            onClick={toggleSidebar}
          />
        </div>
      )}

      {isOpen && !isLargeScreen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300" />
      )}

      <div
        ref={sidebarRef}
        className={`shadow-right fixed top-0 left-0 h-screen flex flex-col bg-white z-50 transition-transform duration-300 ease-in-out w-[150px] sm:w-[170px] md:w-[199px] transform ${isOpen ? "translate-x-0" : "-translate-x-full"}`}
      >
        <div className="p-4">
          <div className="text-cardinal-600 text-xl sm:text-2xl gap-2 font-bold flex justify-center items-center">
            <img
              src="/roadlyne-logo.png"
              className="h-[35.25px]"
              alt="roadlyne logo"
            />
            Roadlyne
          </div>
        </div>

        <div className="flex-1">
          <div className="overflow-y-auto">
            <ul className="p-2">
              {menuItems.map((item) => (
                <li key={item.path}>
                  <Link
                    to={item.path}
                    className={`
                      flex items-center px-4 py-2 my-1 rounded-full
                      ${
                        location.pathname === item.path
                          ? "bg-red-50 text-black"
                          : "text-neutral-800 hover:bg-gray-100"
                      }
                    `}
                  >
                    <img src={item.icon} className="w-5" alt={item.label} />
                    <span className="ml-3">{item.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="border-t">
            <ul className="p-2">
              {bottomMenuItems.map((item) => (
                <li key={item.path}>
                  <Link
                    to={item.path}
                    className="flex items-center px-4 py-2 text-neutral-800 hover:bg-neutral-100 rounded-md"
                  >
                    <span>{item.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="text-center p-4 text-xs text-neutral-500 border-t">
          <p>Terms and Conditions</p>
          <p className="font-semibold text-neutral-800 text-xs">
            ©2024 Roadlyne
          </p>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
