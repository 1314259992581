import { useRef, useEffect, FormEvent } from "react";
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";

// remove optional if needed in future
interface EditConsigmentProps {
  isOpen: boolean;
  onClose: () => void;
  consignor?: string;
  cosignee?: string;
  totalItems?: number | string;
  remaining?: number | string;
}

const BiltyEditConsignmentModal = ({
  isOpen,
  onClose,
  ...rest
}: EditConsigmentProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as HTMLElement;
      if (target.closest('[role="listbox"]')) {
        return;
      }
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // handle form submission logic here
    const formData = new FormData(e.currentTarget);
    const obj = {
      consignor: formData.get("consignor"),
      cosignee: formData.get("cosignee"),
      totalItems: formData.get("totalItems"),
      remaining: formData.get("remaining"),
    };
    console.log(obj);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 h-[100vh] m-0 bg-black bg-opacity-10 flex items-center justify-center z-50 p-10">
      <div
        ref={modalRef}
        className="bg-white rounded-lg w-full max-w-md shadow-lg p-3 text-sm sm:text-base"
      >
        {/* <div className="my-3 text-center w-full font-Bold">
            Edit Details
        </div>
        <Separator /> */}
        <form className="space-y-3 p-5" onSubmit={handleSubmit}>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Consignor
            </Label>
            <Input
              required
              name="consignor"
              // remove ? if optional is removed in future
              defaultValue={rest?.consignor}
              type="text"
              placeholder="Consignor"
              className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
            />
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Consignee
            </Label>
            <Input
              required
              name="consignee"
              // remove ? if optional is removed in future
              defaultValue={rest?.cosignee}
              type="text"
              placeholder="Consignee"
              className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
            />
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Total Items
            </Label>
            <Input
              required
              name="totalItems"
              // remove ? if optional is removed in future
              defaultValue={rest?.totalItems}
              type="number"
              placeholder="Total Items"
              className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
            />
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Remaining
            </Label>
            <Input
              required
              name="remaining"
              // remove ? if optional is removed in future
              defaultValue={rest?.remaining}
              type="number"
              placeholder="Remaining"
              className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
            />
          </div>
          <div className="flex justify-center gap-5 items-center pt-4">
            <Button
              className="bg-cardinal-600 w-24 sm:w-full sm:max-w-[15rem] hover:bg-cardinal-600"
              onClick={onClose}
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BiltyEditConsignmentModal;
