import { ResDataType } from "../DataType/ApiCommon";
import {
  CreateKhataAccountDataType,
  GetKhataAmountDataType,
  GetKhataDataType,
  KhataAmountRequestDataType,
  KhataItemDataType,
  KhataTransactionDataType,
  UpdateKhataAccountDataType,
} from "../DataType/KhataDataType";
import { TRANSPORTER_URL } from "../Utils/EnvVariables";
import HttpClient from "../Utils/HttpClient";

export const GetKataListService = (paramitar?: string): Promise<
  ResDataType<GetKhataDataType>
> => {
  return HttpClient.get(`${TRANSPORTER_URL}/khata/list?${paramitar}`);
};

export const GetKataAmountListService = (
  id: string | number,
): Promise<ResDataType<GetKhataAmountDataType>> => {
  return HttpClient.get(`${TRANSPORTER_URL}/khata/${id}/get-khata-amount-list`);
};

export const AddNewKataService = (
  request: CreateKhataAccountDataType,
): Promise<ResDataType<KhataItemDataType>> => {
  return HttpClient.post(`${TRANSPORTER_URL}/khata/add-new`, request);
};

export const UpdateKataService = (
  request: UpdateKhataAccountDataType,
): Promise<ResDataType<KhataItemDataType>> => {
  return HttpClient.post(`${TRANSPORTER_URL}/khata/update-account`, request);
};

export const AddKataAmountService = (
  id: string | number,
  request: KhataAmountRequestDataType,
): Promise<ResDataType<KhataTransactionDataType>> => {
  return HttpClient.post(`${TRANSPORTER_URL}/khata/${id}/add-amount`, request);
};

export const UpdateKataAmountService = (id: string | number, request: KhataAmountRequestDataType): Promise<ResDataType<KhataTransactionDataType>> => {
  return HttpClient.patch(
    `${TRANSPORTER_URL}/khata/${id}/update-amount`,
    request,
  );
};
