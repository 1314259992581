import { Input } from "../../ui/input";

const BiltyFrightDetailsForm = () => {
  const inputClasses: string =
    "w-full bg-neutral-50 border border-neutral-200 text-neutral-800";

  return (
    <div className="w-full max-w-[56rem] space-y-4">
      <h3 className="font-semibold text-base">Freight Details</h3>

      <div className="flex items-center gap-4">
        <label className="flex items-center gap-2 cursor-pointer">
          <input
            type="radio"
            name="freight"
            value="1"
            className="w-4 h-4 text-blue-600 cursor-pointer"
            defaultChecked
          />
          <span className="text-sm text-gray-700">To Pay</span>
        </label>

        <label className="flex items-center gap-2 cursor-pointer">
          <input
            type="radio"
            name="freight"
            value="2"
            className="w-4 h-4 text-blue-600 cursor-pointer"
          />
          <span className="text-sm text-gray-700">Advance</span>
        </label>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="space-y-4">
          <Input
            type="number"
            required
            name="total_amount"
            placeholder="Total Amount"
            className={inputClasses}
          />
          <Input
            type="number"
            required
            name="remaining_amount"
            placeholder="Remaining"
            className={inputClasses}
          />
          <Input
            type="number"
            required
            name="advance_amount"
            placeholder="Advance"
            className={inputClasses}
          />
        </div>
      </div>
    </div>
  );
};

export default BiltyFrightDetailsForm;
